export function callAPIMiddleware({dispatch, getState}) {
  return next => action => {
    const {types, callAPI, shouldCallAPI = () => true, payload = {}} = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (!Array.isArray(types) || types.length !== 3 || !types.every(type => typeof type === 'string')) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    const [requestType, successType, failureType] = types;

    dispatch(
      Object.assign({}, payload, {
        type: requestType,
      })
    );

    return callAPI().then(
      response => {
        return dispatch(
          Object.assign({}, payload, {
            data: response,
            type: successType,
          })
        );
      },
      error => {
        const err = error.response?.data;
        const status = error.response?.status;

        if (err && (err.code === 1 || err.code === 2 || err.code === 3) && err.object_name) {
          return dispatch(
            Object.assign({}, payload, {
              error: `${err.code}_${err.object_name}`,
              type: failureType,
            })
          );
        }

        if (err && (err.code === 'unique' || err.code === 'invalid' || err.code === 'blank') && err.label) {
          return dispatch(
            Object.assign({}, payload, {
              error: `${err.code}_${err.label}`,
              type: failureType,
            })
          );
        }

        if (err && err.error_code && err.error_code !== 0) {
          return dispatch(
            Object.assign({}, payload, {
              error: `${err.error_code}`,
              type: failureType,
            })
          );
        }

        if (err && err.error_code === 0) {
          return dispatch(
            Object.assign({}, payload, {
              error: `0_${err.invalid_code}`,
              type: failureType,
            })
          );
        }

        return dispatch(
          Object.assign({}, payload, {
            error: String(err?.code),
            type: failureType,
          })
        );
      }
    );
  };
}
