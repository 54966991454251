import { Client } from './document/client';
import { DefaultServerUrl } from '../assets/config.json';
import Auth from '../components/Auth';

export default () => {
  if (Auth.isLoggedIn()) {
    return new Promise(() => {
      Client.setToken(Auth.isLoggedIn());
      Client.setUrl(DefaultServerUrl);
    });
  }
  return new Promise(() => {
    Client.setUrl(DefaultServerUrl);
  });
};
