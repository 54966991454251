const isLoggedIn = () => {
  return localStorage.getItem("token");
};

const setUser = user => {
  const { auth_token: token } = user;
  localStorage.setItem("token", token);
};

const getToken = () => {
  return localStorage.getItem("token");
};

const getCurrentBuilding = () => {
  return localStorage.getItem("current_building");
};

const setCurrentBuilding = id => {
  return localStorage.setItem("current_building", id);
};

const logout = () => {
  localStorage.removeItem("token");
};

const login = user => {
  setUser(user);
};

export default {
  login,
  logout,
  isLoggedIn,
  getToken,
  getCurrentBuilding,
  setCurrentBuilding,
};
