import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import GlobalStyles from '@app/assets/styles/globalStyle';
import {store} from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import {hot} from 'react-hot-loader/root';
import {OpenCvProvider} from 'opencv-react';
import FaceImage from '@app/assets/images/user1.png';
import * as faceapi from 'face-api.js';

const App = () => {
  useEffect(() => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
    ]).then(async () => {
      let img = new Image();
      img.src = FaceImage;
      await faceapi
        .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions({inputSize: 128}))
        .withFaceLandmarks();
    });
  }, []);

  return (
    <OpenCvProvider openCvPath='/opencv/opencv.js'>
      <Provider store={store}>
        <AppProvider>
          <>
            <GlobalStyles />
            <Routes />
          </>
        </AppProvider>
      </Provider>
    </OpenCvProvider>
  );
};

Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
