import axios from 'axios';
/* eslint-disable no-throw-literal */

export default class Client {
  constructor() {
    this.url = '';
    this.urlVersion = '';
    this.token = '';
  }

  cleanObject = p => {
    const params = {...p};
    for (const key of Object.keys(params)) {
      const value = String(params[key]);
      if (value === 'null' || value === 'undefined' || value === '') {
        delete params[key];
      }
    }
    return params;
  };

  getUrl = () => {
    return this.url;
  };

  setUrl = url => {
    this.url = url;
  };

  getToken = () => {
    return this.token;
  };

  setToken = token => {
    this.token = token;
  };

  getBaseRoute = () => {
    return `${this.url}${this.urlVersion}`;
  };

  // ekyc

  createSession = params => {
    return this.doFetch({
      method: 'post',
      url: `${this.getBaseRoute()}/sessions`,
      data: params,
    });
  };

  uploadFront = ({session_id, img, doc_type}) => {
    let formData = new FormData();
    formData.append('img', img);
    return this.doFetch({
      method: 'post',
      params: {doc_type},
      url: `${this.getBaseRoute()}/sessions/${session_id}/upload_front`,
      data: formData,
    });
  };

  uploadBack = ({session_id, img}) => {
    let formData = new FormData();
    formData.append('img', img);
    return this.doFetch({
      method: 'post',
      url: `${this.getBaseRoute()}/sessions/${session_id}/upload_back`,
      data: formData,
    });
  };

  uploadUserImage = ({session_id, left, right, center, nod}) => {
    let formData = new FormData();
    formData.append('left', left);
    formData.append('right', right);
    formData.append('center', center);
    formData.append('nod', nod);
    return this.doFetch({
      method: 'post',
      url: `${this.getBaseRoute()}/sessions/${session_id}/upload_image`,
      data: formData,
    });
  };

  getUserImage = user_image_id => {
    return this.getImage(`${this.getBaseRoute()}/user_images/${user_image_id}`);
  };

  getDocumentImage = document_image_id => {
    return this.getImage(`${this.getBaseRoute()}/document_images/${document_image_id}`);
  };

  validateRecord = (session_id, form) => {
    return this.doFetch({
      method: 'put',
      url: `${this.getBaseRoute()}/sessions/${session_id}/validate`,
      data: form,
    });
  };

  getInfo = q => {
    return this.doFetch({
      method: 'post',
      url: `${this.getBaseRoute()}/card_info`,
      data: {q},
    });
  };

  getImage = url => {
    return axios({
      method: 'get',
      url,
      responseType: 'blob',
    }).then(res => {
      if (res.data?.type.indexOf('image') === -1) {
        return null;
      }
      return new Blob([res.data], {type: res.data?.type});
    });
  };

  doFetch = ({method, url, params = {}, data = {}, onUploadProgress = () => {}}) => {
    const newParams = this.cleanObject(params);
    return axios({
      method,
      url,
      params: newParams,
      data,
      onUploadProgress,
    }).then(res => {
      return res.data;
    });
  };
}
