import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import Loader from '@app/components/utility/loader';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('./containers/Pages/StepOne1')),
  },
  {
    path: PUBLIC_ROUTE.GKS,
    exact: true,
    component: lazy(() => import('./containers/Pages/StepOne1')),
  },
  {
    path: PUBLIC_ROUTE.WELCOME,
    exact: true,
    component: lazy(() => import('./containers/Pages/Welcome')),
  },
  {
    path: PUBLIC_ROUTE.STEP_ONE_DOT_ONE,
    exact: true,
    component: lazy(() => import('./containers/Pages/StepOne1')),
  },
  {
    path: PUBLIC_ROUTE.STEP_ONE_DOT_TWO,
    exact: true,
    component: lazy(() => import('./containers/Pages/StepOne2')),
  },
  {
    path: PUBLIC_ROUTE.STEP_TWO,
    exact: true,
    component: lazy(() => import('./containers/Pages/StepTwo')),
  },
  {
    path: PUBLIC_ROUTE.STEP_THREE,
    exact: true,
    component: lazy(() => import('./containers/Pages/StepThree')),
  },
  {
    path: PUBLIC_ROUTE.SUCCESSFUL_RESULT,
    exact: true,
    component: lazy(() => import('./containers/Pages/Success')),
  },
  {
    path: PUBLIC_ROUTE.ERROR_RESULT,
    exact: true,
    component: lazy(() => import('./containers/Pages/Error')),
  },
  {
    path: PUBLIC_ROUTE.INFORMATION,
    exact: true,
    component: lazy(() => import('./containers/Pages/Information')),
  },
];

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <Redirect to='/step-one-dot-one' />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
