import {combineReducers} from 'redux';
import App from '@app/redux/app/reducer';
import ThemeSwitcher from '@app/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@app/redux/languageSwitcher/reducer';
import document from './document/reducers';

const appReducer = combineReducers({
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  document,
});

export default appReducer;
